
import api from '@/services/api'
import Router from '@/router/index';

const store = {
  namespaced: true,
  state: {
    company: null,
    name: null,
    token: null
  },
  mutations: {
    settoken (state, token) {
      state.token = token
    },
    setcompany (state, company) {
      state.company = company
    },
    setname (state, name) {
      state.name = name
    }
  },
  getters: {
    getToken: state => {
      return state.token
    }
  },
  actions: {
    logout ({commit, dispatch}) {
      commit('settoken', null)
      // appeler resetRegisterToken du store config
			dispatch('config/resetRegisterToken', '', {root:true});


      Router.push('login')
    },
    async attemptLogin ({ commit, state }, {username, password}) {

      try {
        let call = await api.post({token: null, url: 'intervention/login', params: {username, password}});

        commit('settoken', call.token)
        commit('setcompany', call.response.company)
        commit('setname', call.response.name)
        return true;
      }catch (err) {
        if (err.response.data.response.message) {
          return err.response.data.response.message;
        }
      }
      return false;
    },
    async updatePassword ({ commit, state }, {oldpassword, newpassword, repassword}) {
      try {
        let call = await api.post({url: 'intervention/changepassword', params: {oldpassword, newpassword, repassword}});

        return true;
      }catch (err) {
        if (err.response.data.response.message) {
          return err.response.data.response.message;
        }
      }
      return false;
    }
  }
}

export default store